<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getQrclsNow()"
          label-width="120px">
          <el-row>
            <el-col :md="6">
              <el-form-item label="材料采购号:" prop="modr_no">
                <el-input size="small" v-model.trim="searchForm.modr_no" clearable placeholder="请填写材料采购号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="成品采购号:" prop="podr_no">
                <el-input size="small" v-model.trim="searchForm.podr_no" clearable placeholder="请填写成品采购号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="材料名称:" prop="mtrb_name">
                <el-input size="small" v-model.trim="searchForm.mtrb_name" clearable placeholder="请填写材料名称"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getQrclsNow()" class="vg_ml_16">查询
                </el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i
                    class="el-icon-refresh-right"></i> 刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
      </div>
      <el-row>
        <el-col :md="24">
          <el-table class="vg_cursor" ref="multiTable" :data="tableData" border v-loading="loadFlag">
            <el-table-column label="材料采购号" prop="modr_no" />
            <el-table-column label="成品采购号" prop="podr_no" />
            <el-table-column label="材料名称" prop="mtrb_name" />
            <el-table-column label="箱唛二维码" prop="qr1">
              <template slot-scope="scope">
                <div v-if="scope.row.qr1">
                  <imgColorPop :macpFlag="scope.row.qr1" :macpFlagO="2" :downloadFlag="1"></imgColorPop>
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="工厂收货二维码" prop="qr2">
              <template slot-scope="scope">
                <div v-if="scope.row.qr2">
                  <imgColorPop :macpFlag="scope.row.qr2" :macpFlagO="2" :downloadFlag="1"></imgColorPop>
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="工厂入库二维码" prop="qr3">
              <template slot-scope="scope">
                <div v-if="scope.row.qr3">
                  <imgColorPop :macpFlag="scope.row.qr3" :macpFlagO="2" :downloadFlag="1"></imgColorPop>
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage='totalPage' @changePageSearch="changePageSearch" ref="pubPagination">
          </pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { qrclAPI } from "@api/modules/qrcl";
import pubPagination from "@/components/common/pubPagination";
import imgColorPop from "@/views/component/imgColorPop"
export default {
  name: "QrclList",
  components: {
    pubPagination,
    imgColorPop,
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        page_no: 1,
        modr_no: null,
        podr_no: null,
        mtrb_name: null,
      },
      totalPage: 0,
      btn: {},
      loadFlag: false,
      multiSelection: [],
      currentPage: 1,
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.loadFlag = true
      this.getQrclsList()
    },
    // 获取供应商信息
    getQrclsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.time)
      get(qrclAPI.getQrcls, {
        modr_no: this.searchForm.modr_no,
        mtrb_name: this.searchForm.mtrb_name,
        podr_no: this.searchForm.podr_no,
        page_no: this.searchForm.page_no,
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            console.log('this.tableData',this.tableData);
            for(let j=0;j<this.tableData.length;j++){
                let updateTime = JSON.parse(JSON.stringify(new Date().getTime()))
                if(this.tableData[j].qr1){
                  this.tableData[j].qr1 = this.helper.picUrl(this.tableData[j].qr1,'l',updateTime,0)
                }
                if(this.tableData[j].qr2){
                  this.tableData[j].qr2 = this.helper.picUrl(this.tableData[j].qr2,'l',updateTime,0)
                }
                if(this.tableData[j].qr3){
                  this.tableData[j].qr3 = this.helper.picUrl(this.tableData[j].qr3,'l',updateTime,0)
                }
              }
            setTimeout(() => {
              this.loadFlag = false
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    },
    // 刷新
    buttonRefresh() {
      this.$refs.searchForm.resetFields()
      this.$refs.pubPagination.currentPage = 1
      this.initData()
    },
    // 查询方法
    getQrclsNow() {
      this.$refs.pubPagination.currentPage = 1
      this.searchForm.page_no = 1
      this.initData()
    },
    // 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val
      this.initData()
    },
  }
}
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
